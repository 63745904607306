import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { getLearnAboutMaayot } from 'redux/actions/onboardingAction'
import { SET_LEARN_ABOUT, SET_LEARN_ABOUT_TYPE } from 'redux/types'

const HowDidYouHear = ({ nextStep }) => {
  const dispatch = useDispatch()
  const learnAbout = useSelector<any>(({ onboarding }) => onboarding.learnAbout) || []
  const learnAboutType = useSelector<any>(({ onboarding }) => onboarding.learnAboutType) || ''

  const handle = {
    navigate: async (isSkip: boolean) => {
      if (isSkip) {
        nextStep(6)
        return
      }
      if (!learnAboutType) return
      nextStep(5)
    },
    getLearnAboutMaayot: async () => {
      await getLearnAboutMaayot()
    },
    onClickHearType: (type: string) => {
      dispatch({
        type: SET_LEARN_ABOUT_TYPE,
        payload: type,
      })
    },
  }

  useEffect(() => {
    dispatch({
      type: SET_LEARN_ABOUT,
      payload: [],
    })
    handle.getLearnAboutMaayot()
  }, [])

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <div className='w-container'>
        <div className='choose-lunguage-main-div overflow-hidden-mob'>
          <div className='common-title-sec'>
            <h2 className='screen-sub-title'>How did you learn about maayot?</h2>
          </div>

          <div className='survey-btn-main-div'>
            {Array.isArray(learnAbout) &&
              learnAbout?.map((text, index) => (
                <button
                  onClick={() => handle.onClickHearType(text)}
                  key={text + index}
                  className={`how_learned_btn ${learnAboutType === text ? 'outline-btn-active' : ''}`}
                >
                  {text}
                </button>
              ))}
          </div>

          <div className='text-center'>
            <button
              className='common-btn-cls green-btn-class'
              onClick={() => handle.navigate(false)}
              data-aos='fade-In'
              data-aos-duration='1700'
              data-aos-easing='linear'
              data-aos-once='true'
              data-aos-offset='0'
            >
              Next
            </button>

            {/* <p
              className='links-text'
              style={{ textDecoration: 'underline', fontWeight: '700' }}
              onClick={() => handle.navigate(true)}
              data-aos='fade-In'
              data-aos-duration='2000'
              data-aos-easing='linear'
              data-aos-once='true'
              data-aos-offset='0'
            >
              Skip to result
            </p> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default HowDidYouHear
