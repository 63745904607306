import AOS from 'aos'
import 'aos/dist/aos.css'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const LearnWith = ({ nextStep }) => {
  const { languageInfo }: any = useSelector<any>(({ onboarding }) => onboarding)

  const handle = {
    navigate: () => {
      const step = languageInfo?.preferences?.length ? 2 : 3
      nextStep(step)
    },
  }

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <div className='w-container'>
        <div className='why-learn-main-div'>
          <div className='common-title-sec'>
            <h2
              className='screen-sub-title'
              data-aos='fade-down'
              data-aos-duration='1000'
              // data-aos-easing='linear'
              data-aos-once='true'
              data-aos-delay='100'
            >
              Why learn with
              <img className='text-logo' alt={'Logo'} src={'/images/maayot-text-logo.png'} />
              {/* <span className='green-color DM-Sans-font'>maayot</span> */}
            </h2>
          </div>
          <div
            className='why-learn-card-div'
            data-aos='fade-down'
            data-aos-duration='1000'
            data-aos-delay='400'
            // data-aos-easing='linear'
            data-aos-once='true'
          >
            <div className='left-div-cls'>
              <div className='img-div'>
                <img alt={'Image'} src={'/images/Education-image.svg'} />
              </div>
            </div>
            <div className='right-div-cls'>
              <div className='contains-div'>
                <h5>The maayot methodology</h5>
                <p>
                  Grounded in decades of scientific research, maayot leverages your brain's neuroplasticity to feed you{' '}
                  <b>reading, listening, speaking and writing practice</b> that is exactly at the right level for you. 
                  <br></br>Consuming as much content in a language as you can is the best alternative to dull and
                  repetitive memorisation of words and grammar rules.
                </p>
              </div>
            </div>
          </div>
          <div className='text-center'>
            <button
              className='common-btn-cls green-btn-class'
              onClick={() => handle.navigate()}
              data-aos='fade-down'
              data-aos-duration='1000'
              // data-aos-easing='linear'
              data-aos-once='true'
              data-aos-offset='0'
              data-aos-delay='400'
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default LearnWith
