import Header from 'containers/header-old/HeaderOld'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllLanguage, getWordsList } from 'redux/actions/onboardingAction'
import history from '../../browserHistory'
import { RESET_ONBOARDING, SET_ALL_LANGUAGE, SET_ALL_WORDS, SET_LANGUAGE_INFO, SET_STEPPER_STEP } from 'redux/types'
import HeaderOld from 'containers/header-old/HeaderOld'

const getIcon = (lang: string) => {
  if (lang === 'Chinese') return '/images/language-icon-ch.png'
  if (lang === 'Korean') return '/images/language-icon-kr.png'
  if (lang === 'French') return '/images/language-icon-fr.png'

  return '/images/language-icon-ch.png'
}

const ChooseLanguage = () => {
  const dispatch = useDispatch()
  const onBoardingState: any = useSelector<any>(({ onboarding }) => onboarding)
  const userState: any = useSelector<any>(({ user }) => user)

  const handle = {
    selectLanguage: async (languageData) => {
      languageData?.preferences?.forEach((preference, index) => (preference.is_active = index === 0 ? true : false))
      dispatch({ type: SET_LANGUAGE_INFO, payload: languageData })

      if (languageData?.preferences?.length < 1) {
        const response = await getWordsList({ language_id: languageData?._id })

        dispatch({ type: SET_ALL_WORDS, payload: { wordList: response?.data } })
      }
      dispatch({ type: SET_STEPPER_STEP, payload: 1 })
      history.push(
        `/learn-${languageData?.language?.toLowerCase()}/${
          userState?.authenticated ? 'existing' : onBoardingState?.refUser ? 'lead' : 'new'
        }`,
      )
    },
    getAllLanguage: async () => {
      dispatch({ type: RESET_ONBOARDING })
      const response = await getAllLanguage()
      dispatch({ type: SET_ALL_LANGUAGE, payload: response?.data })
    },
  }

  useEffect(() => {
    handle.getAllLanguage()
  }, [])

  return (
    <>
      <HeaderOld />
      <div className='w-container'>
        <div className='common-padding-div mob-lr-padding'>
          <div className='choose-lunguage-main-div'>
            <div className='common-title-sec'>
              <h2 className='screen-common-title'>Choose a Language to start</h2>
            </div>
            <div className='survey-btn-main-div'>
              {onBoardingState?.languages?.map((languageInfo) => (
                <button
                  onClick={() => handle.selectLanguage(languageInfo)}
                  key={languageInfo?._id}
                  className={'lunguage-btn-cls btn'}
                >
                  <img
                    className='lunguage-img'
                    alt={'icon'}
                    //
                    src={getIcon(languageInfo?.language)}
                  />
                  {languageInfo?.language}
                </button>
              ))}
            </div>
            {/* <div className='text-center'>
              <button className='common-btn-cls outline-btn-class btn' disabled>
                View all languages
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ChooseLanguage
