import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import history from '../../browserHistory'
import { useSelector } from 'react-redux'
import { characterConst } from 'shared/constant'

const SignUp = () => {
  const { languageInfo, preferenceType }: any = useSelector<any>(({ onboarding }) => onboarding)
  useEffect(() => {
    AOS.init()
  }, [])

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://api.memberstack.io/static/memberstack.js?webflow'
    script.setAttribute('data-memberstack-id', '16994eed7bdde3781a57968be24dd38e')
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    history.push(history.location.pathname)
  }, [history])

  return (
    <div className='w-container'>
      <div className='choose-lunguage-main-div'>
        <div className='common-title-sec'>
          <h2
            className='screen-sub-title'
            data-aos='fade-down'
            data-aos-duration='1000'
            data-aos-once='true'
            data-aos-delay='100'
          >
            Save your vocabulary and start learning with maayot
          </h2>
          <p
            className='screen-para-text'
            data-aos='fade-down'
            data-aos-duration='1000'
            data-aos-delay='400'
            data-aos-once='true'
          >
            View your {languageInfo?.language || 'Chinese'} level and save your personal vocabulary.
          </p>
        </div>
        <div className='login-field-main-div'>
          <div data-aos='fade-down' data-aos-duration='1000' data-aos-delay='1000' data-aos-once='true'>
            <form
              id='email-form'
              name='email-form'
              data-name='Email Form'
              data-ms-form='signup'
              className='form-2 margin-top'
            >
              <div className='form-group' style={{ display: 'none' }}>
                <input
                  type='text'
                  style={{ visibility: 'hidden' }}
                  value={preferenceType || characterConst.simplified}
                  name='Character'
                  data-name='Character'
                  id='Character'
                  data-ms-member='character'
                />
              </div>
              <div className='form-group' style={{ display: 'none' }}>
                <input
                  type='text'
                  style={{ visibility: 'hidden' }}
                  value='Beginner'
                  name='level'
                  data-name='level'
                  id='level-2'
                  data-ms-member='level'
                />
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-input-2 w-input'
                  maxLength={256}
                  name='full-name-2'
                  data-name='Full Name 2'
                  placeholder='First Name'
                  id='full-name-2'
                  data-ms-member='first-name'
                  required
                />
              </div>
              <div className='form-group'>
                <input
                  type='email'
                  className='form-input-2 w-input'
                  maxLength={256}
                  name='email-2'
                  data-name='Email 2'
                  placeholder='Email address'
                  id='email-2'
                  data-ms-member='email'
                  required
                />
              </div>
              <div className='form-group' style={{ marginBottom: '0px' }}>
                <input
                  type='password'
                  className='form-input-2 w-input'
                  maxLength={256}
                  name='Password 2'
                  data-name='Password 2'
                  placeholder='Create password'
                  id='password-2'
                  data-ms-member='password'
                  required
                />
              </div>

              <input
                type='submit'
                value='View result'
                data-wait='Please wait...'
                className='common-btn-cls green-btn-class input-btn'
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp
