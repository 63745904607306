import { INTRODUCTION, STORY_FINISHED, STORY, QUIZ, QUESTION } from '../paths'
import { Converter } from 'opencc-js'

export const hongKongTZ = 'Asia/Hong_Kong'
export const SUNDAY = 'Sun'
export const offsetTrans = 25
export const hourInDay = 24
export const milisInDay = 60 * 60 * 24 * 1000
export const milisInWeek = 7 * milisInDay
export const serverDateFormat = 'YYYY-MM-DD'

export const converterType = {
  converterTraditional: Converter({ from: 'cn', to: 'hk' }),
  converterSimplified: Converter({ from: 'hk', to: 'cn' }),
}
export const countDownCheckTime = {
  Orange: 150,
  Red: 30,
}
export enum countDownLabel {
  Green = 'green',
  Orange = 'orange',
  Red = 'red',
}

export enum levelConst {
  beginner = 'beginner',
  advanced = 'advanced',
  intermediate = 'intermediate',
}
export enum characterConst {
  traditional = 'Traditional',
  simplified = 'Simplified',
}
export enum storyStep {
  Intro,
  Step1,
  Step2,
  Step3,
  Step4,
}

export enum wordType {
  NewWord,
  OldWord,
  Word,
}

export enum membershipType {
  Free = 'Free',
  Paid = 'Paid',
  Standard = 'Standard',
}

export enum membershipNameType {
  Free = 'maayot Free',
  Standard = 'maayot Standard',
  School = 'School',
  Premium = 'maayot Premium',
  Pro = 'maayot Pro',
  Teacher = 'maayot Teacher',
}

export enum countDownType {
  Week,
  Day,
}

export enum MemberStepInStory {
  INTRODUCTION = 'introduction',
  STORY_AND_LISTENING = 'story and listening',
  QUIZ = 'quiz',
  QUESTION = 'question',
  FINISH = 'finish',
}

export const StepMap2Link = {
  [MemberStepInStory.INTRODUCTION]: INTRODUCTION,
  [MemberStepInStory.STORY_AND_LISTENING]: STORY,
  [MemberStepInStory.QUIZ]: QUIZ,
  [MemberStepInStory.QUESTION]: QUESTION,
  [MemberStepInStory.FINISH]: STORY_FINISHED,
}

export const StepMap2Enum = {
  [MemberStepInStory.INTRODUCTION]: storyStep.Intro,
  [MemberStepInStory.STORY_AND_LISTENING]: storyStep.Step1,
  [MemberStepInStory.QUIZ]: storyStep.Step2,
  [MemberStepInStory.QUESTION]: storyStep.Step3,
  [MemberStepInStory.FINISH]: storyStep.Step4,
}

export enum accountTab {
  Streak,
  MyAccount,
  Plan,
  Level,
  Character,
  Integrations,
}

export const languageList = {
  chinese: 'Chinese',
  korean: 'Korean',
  french: 'French',
}

export const getPlanInfo: any = (name) => {
  switch (name) {
    case membershipNameType.Standard:
      return {
        mainText: 'Get teacher’s daily feedback with the Pro plan',
        subText: 'Improve your speaking, writing and grammar with daily corrections by our teacher.',
        title: 'Standard',
      }
    case membershipNameType.Free:
      return {
        mainText: 'Get more stories with the Standard plan',
        subText: 'Improve your reading and listening comprehension, and learn new words faster with a daily new story.',
        title: 'Free',
      }
    case membershipNameType.Pro:
      return {
        title: 'Pro',
      }
    default:
      return null
  }
}

export const PlanChangeLink = 'https://www.maayot.com/account'
