import { INTRODUCTION, STORIES, STORY_FINISHED } from 'paths'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import uuid from 'react-uuid'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { membershipNameType } from 'shared/constant'
import history from '../../browserHistory'
import { answerValidate, epochTimeDifferenceInText, idValidAccount, isSunday } from 'utils/utils'
import { getStory, setPreventRedirect } from '../../redux/actions/storyAction'
import Sidebar from './Sidebar'
import PLayer from 'containers/player/Player'
import Recorder from 'containers/recorder/Recorder'
import { getSignedUrl, uploadFileOnS3 } from 'utils/S3Upload'
import { logoutUser } from 'redux/actions/userActions'
import Diff from 'containers/Diff'
import { setCorrections, setCurrentCorrection, submitCorrection } from 'redux/actions/dashboardAction'
import Loader from 'containers/loader/loader'
import Story from 'components/quiz/Story'
import { getQuestion } from 'redux/actions/questionAction'
import { setAudioRecording } from 'redux/actions/recorderAction'

const Dashboard: React.FC = (props: any) => {
  const { customFields, memberId } = props.credentials
  const level = customFields.level || props.credentials?.level
  const chineseWordFontSize = useSelector<any>(({ dashboard }) => dashboard?.chineseWordFontSize)
  // eslint-disable-next-line prefer-const
  let { currentStoryCorrection } = props
  const { question } = props.question
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isStoryAvailable, setIsSisStoryAvailable] = useState(false)
  const [showStoryLine, setShowStoryLine] = useState(true)
  const [audioRecordingUrl, setAudioRecordingUrl] = useState('')
  const [correctionRecordingUrl, setCorrectionRecordingUrl] = useState('')
  const [answer, setAnswer] = useState('')
  const [recordingError, setRecordingError] = useState('')
  const [answerError, setAnswerError] = useState({ error: '' })
  const [firstLineStory, setFirstLineStory] = useState<any>({})

  const isTeacher = props.membership?.name === membershipNameType.Teacher

  const onLogout = () => {
    logoutUser()
  }

  const onMoveToIntro = () => {
    history.push(STORIES)
  }

  const onTextChange = (e) => {
    setAnswer(e.target.value)
  }

  useEffect(() => {
    setFirstLineStory({})
    const firstLineWords: string[] = []
    let firstLineBreak = false

    props?.story?.storyContent?.forEach((wordObj) => {
      firstLineBreak =
        !firstLineBreak && (wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')) ? true : firstLineBreak

      if (!firstLineBreak) firstLineWords.push({ ...wordObj })

      return wordObj
    })
    setFirstLineStory({
      arContent: firstLineWords,
      storyContent: firstLineWords,
      newWord1: props?.story.newWord1,
      newWord2: props?.story.newWord2,
      oldWords: props?.story.oldWords,
      showPinYin: false,
    })
  }, [props.story])

  useEffect(() => {
    setFirstLineStory({})
    if (props.authenticated) {
      if (isTeacher) {
        return
      } else if (idValidAccount(props.membership?.name, props.membership?.status) || isSunday()) {
        history.push(INTRODUCTION)
      } else {
        history.push(STORY_FINISHED)
      }
    }
  }, [])

  useEffect(() => {
    if (props?.currentAudioRecording.size && props?.currentAudioRecording.size !== undefined) setRecordingError('')
  }, [props?.currentAudioRecording.size])

  useEffect(() => {
    if (currentStoryCorrection?.data?.storyId && currentStoryCorrection?.data?.level)
      Promise.all([
        props.getStory(currentStoryCorrection?.data?.storyId, currentStoryCorrection?.data?.level, memberId),
        props.getQuestion(currentStoryCorrection?.data?.storyId, currentStoryCorrection?.data?.level, memberId),
      ]).then(() => setIsSisStoryAvailable(true))
  }, [currentStoryCorrection?.data, memberId])

  useEffect(() => {
    setAudioRecording({})
    setIsSisStoryAvailable(false)
    setAudioRecordingUrl('')
    setCorrectionRecordingUrl('')
    setAnswer('')
    setAnswerError({ error: '' })
    setRecordingError('')
    if (currentStoryCorrection?.data?.recordingUrl && currentStoryCorrection?.data?.recordingUrl !== '') {
      getSignedUrl({ key: currentStoryCorrection?.data?.recordingUrl })
        .then((res) => {
          setAudioRecordingUrl(res)
        })
        .catch(() => setAudioRecordingUrl(''))
    }
    if (
      currentStoryCorrection?.data?.correctedRecordingUrl &&
      currentStoryCorrection?.data?.correctedRecordingUrl !== ''
    ) {
      getSignedUrl({ key: currentStoryCorrection?.data?.correctedRecordingUrl })
        .then((res) => {
          setCorrectionRecordingUrl(res)
        })
        .catch(() => setCorrectionRecordingUrl(''))
    }
  }, [currentStoryCorrection?.data])

  const onSubmitAnswer = async () => {
    let validationFailed = 0
    if (
      'recordingUrl' in currentStoryCorrection?.data &&
      currentStoryCorrection?.data?.recordingUrl !== '' &&
      (props.currentAudioRecording.size === undefined || !props.currentAudioRecording.size)
    ) {
      validationFailed++
      setRecordingError('Please record correction to submit')
    }

    if ('answer' in currentStoryCorrection?.data && currentStoryCorrection?.data?.answer !== '') {
      const answerHasError = await answerValidate(answer)
      validationFailed = answerHasError.error !== '' ? validationFailed + 1 : validationFailed
      setAnswerError(answerHasError)
    }

    if (validationFailed > 0) return

    if (!isSubmitting) {
      setIsSubmitting(true)

      try {
        let uploadResponse
        if (props.currentAudioRecording.size) {
          const uploadKey = `${memberId}/${currentStoryCorrection?.data.storyId}-${uuid()}`
          const payload = {
            uploadKey,
            file: props.currentAudioRecording,
            contentType: props.currentAudioRecording?.type,
          }
          uploadResponse = await uploadFileOnS3(payload)
        }
        props
          ?.submitCorrection(
            currentStoryCorrection?.data.storyId,
            currentStoryCorrection?.data.memberId,
            answer,
            uploadResponse !== undefined ? uploadResponse?.Key : '',
          )
          .then((res) => {
            setIsSubmitting(false)
            props.setCurrentCorrection({ ...res, ref: currentStoryCorrection.ref })
            props.corrections.forEach((correction) => {
              if (correction.ref === currentStoryCorrection.ref) correction.data.isOpen = false
              return correction
            })
            props.setCorrections([...props.corrections])
          })
          .catch(() => setIsSubmitting(false))
      } catch (e) {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <>
      <div className='dashboard-main-div w-d-grid'>
        <Sidebar></Sidebar>

        <div className='right-side mb-30 mt-30'>
          <div ms-membership='free' className='section-2'>
            <div className='section-story'>
              <div className='w-row border-bottom w-d-flex'>
                <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6  mt-10 mb-10 content-left'>
                  <span className='introduction-title'>Submitted Answer</span>
                </div>
                <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6 content-right'>
                  <button onClick={onMoveToIntro} className='button-2 bg-gray-4 login w-button mr-10'>
                    Story
                  </button>
                  <button onClick={onLogout} className='button-2 bg-gray-4 login w-button'>
                    Log Out
                  </button>
                </div>
                {/* <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6  pinyin-button pr-10'>
                  <button className='button-2 bg-light-gray w-button'>jkl</button>
                </div> */}
              </div>

              {props?.currentStoryCorrection && isStoryAvailable ? (
                <>
                  <div className='w-nav story-nav-content'>
                    <span className='story-nav-pre-heading'>
                      Submitted {epochTimeDifferenceInText(currentStoryCorrection?.data?.createdAt)} ago
                    </span>
                    <div className='story-nav-user-introduction'>
                      <h3 className='story-nav-content-title'>
                        {currentStoryCorrection?.data.memberName}
                        <span
                          className={
                            currentStoryCorrection?.data.level === 'Advanced'
                              ? 'm-b-error color-white text-capitalize'
                              : currentStoryCorrection?.data.level === 'Intermediate'
                              ? 'm-b-primary-2 color-white text-capitalize'
                              : 'm-b-primary-1 color-white text-capitalize'
                          }
                        >
                          {currentStoryCorrection?.data?.level}
                        </span>
                      </h3>
                    </div>

                    <span className='story-nav-id'> ID: {currentStoryCorrection?.data.memberId} </span>
                  </div>
                  <div className='w-row  w-d-flex section-story-content mb-20'>
                    <div className='w-col-12 w-col-medium-12 w-col-small-12 w-col-tiny-12 content-left box-padding'>
                      <div className='answer-box box-border'>
                        <span className='box-title '> Story </span>
                        <span className='up-arrow' onClick={() => setShowStoryLine(!showStoryLine)}>
                          <i className={showStoryLine ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i>
                        </span>
                        {showStoryLine ? (
                          <>
                            <hr className='story-bottom-border'></hr>
                            <div className='story-content story-content-padding'>
                              {firstLineStory?.arContent && firstLineStory?.arContent.length ? (
                                <Story
                                  skritter_token={props?.skritter_token}
                                  user_auth_token={props?.user_auth_token}
                                  story={firstLineStory}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='w-row  w-d-flex section-story-content'>
                    <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6 content-left box-padding border-right'>
                      <div className='answer-box box-border'>
                        <div className='copy-title-div'>
                          {question ? <h4>{question}</h4> : ''}
                          {currentStoryCorrection?.data?.answer ? (
                            <CopyToClipboard text={currentStoryCorrection?.data?.answer}>
                              <button className='copy-icon'>
                                <i className='fa fa-clone'></i>
                              </button>
                            </CopyToClipboard>
                          ) : (
                            ''
                          )}
                        </div>
                        <span className='box-title'> The Answer </span>

                        <div className='story-content story-words'>
                          {currentStoryCorrection?.data?.answer
                            ? currentStoryCorrection?.data?.answer
                            : 'No answer submitted'}
                        </div>
                        {audioRecordingUrl && audioRecordingUrl !== '' ? (
                          <div className='w-row w-col-11 w-col-medium-11 w-col-small-12 w-col-tiny-12 box-audio'>
                            <PLayer audio={audioRecordingUrl}></PLayer>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className='w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6 content-left'>
                      <div className='correction-box'>
                        <div className='correction-box-content box-padding'>
                          <div className='box-title mb-10'> Corrections </div>
                          <div className='correction-words'>
                            {/* Add text area*/}
                            {!currentStoryCorrection?.data?.isOpen ? (
                              <div className='story-content story-words'>
                                {currentStoryCorrection?.data?.correctedAnswer
                                  ? currentStoryCorrection?.data?.correctedAnswer
                                  : 'No answer correction submitted'}
                              </div>
                            ) : (
                              <>
                                <textarea
                                  rows={4}
                                  placeholder='Your answer in Chinese Language....'
                                  value={answer}
                                  onChange={onTextChange}
                                  className={answerError?.error !== '' ? 'input-error' : ''}
                                  disabled={
                                    !('answer' in currentStoryCorrection?.data) || !currentStoryCorrection?.data?.isOpen
                                  }
                                />
                                {answerError?.error !== '' && (
                                  <div className='error'>
                                    <i className='fa fa-exclamation-circle' /> {answerError?.error}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className='preview-box-contenr box-padding border-top'>
                          {currentStoryCorrection?.data?.answer?.length ? (
                            <>
                              <span className='box-title'> Preview </span>
                              <div className='story-content preview-words'>
                                {answer.length || currentStoryCorrection?.data?.correctedAnswer?.length ? (
                                  <Diff
                                    string1={currentStoryCorrection?.data?.answer}
                                    string2={
                                      currentStoryCorrection?.data?.correctedAnswer
                                        ? currentStoryCorrection?.data?.correctedAnswer
                                        : answer
                                    }
                                    mode='characters'
                                    chineseWordFontSize={chineseWordFontSize}
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                          <div className='w-row w-col-12 w-col-medium-11 w-col-small-12 w-col-tiny-12 box-audio'>
                            {!currentStoryCorrection?.data?.isOpen && correctionRecordingUrl !== '' ? (
                              <PLayer audio={correctionRecordingUrl}></PLayer>
                            ) : audioRecordingUrl && audioRecordingUrl !== '' ? (
                              <>
                                <Recorder></Recorder>
                                {recordingError !== '' && (
                                  <div className='error'>
                                    <i className='fa fa-exclamation-circle' /> {recordingError}
                                  </div>
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='correction-button mt-30'>
                    {currentStoryCorrection?.data?.isOpen ? (
                      <button className='button-2 w-button' onClick={onSubmitAnswer}>
                        {!isSubmitting ? 'Submit Correction' : <Loader showText={false} />}
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  user_auth_token: state.user.user_auth_token,
  skritter_token: state.user.skritter_token,
  currentStoryCorrection: state.dashboard.currentStoryCorrection,
  currentAudioRecording: state.recorder.currentAudioRecording,
  authenticated: state.user.authenticated,
  membership: state.user.membership,
  credentials: state.user.credentials,
  errors: state.UI.errors,
  story: state.story.currentStory,
  question: state.question,
  corrections: state.dashboard.corrections,
})
const mapActionsToProps = {
  getStory,
  getQuestion,
  submitCorrection,
  setCurrentCorrection,
  setPreventRedirect,
  setCorrections,
  setAudioRecording,
}
export default connect(mapStateToProps, mapActionsToProps)(Dashboard)
