import {
  SET_ERRORS,
  SET_QUESTION,
  SET_QUESTION_ANSWER_LIST,
  SET_SUBMIT_QUESTION,
  CLEAR_QUESTION_ANSWER_LIST,
} from '../types'

import MAAYOT_API, { JSON_HEADER } from './config'

export const clearAnswer = () => (dispatch: any) => {
  dispatch({
    type: CLEAR_QUESTION_ANSWER_LIST,
  })
}
export const getQuestion = (id: string, level: string, memberId: string) => (dispatch: any) => {
  return new Promise<void>((resolve, reject) => {
    fetch(`${MAAYOT_API}question/?storyId=${id}&level=${level?.toLowerCase() || ''}&memberId=${memberId}`, {
      method: 'GET',
      headers: JSON_HEADER,
    })
      .then((resData) => resData.json())
      .then((data) => {
        const temp: any = []
        if (data && !data.status && !data.statusCode && Object.keys(data) && Object.keys(data).length > 0) {
          dispatch({
            type: SET_QUESTION,
            payload: {
              question: data.question,
              memberAnswer: data.memberAnswer && data.memberAnswer.data.answer,
              questionContent: data.questionContent,
            },
          })
          const answers = data?.historicalAnswers.data?.map((ans) => {
            temp.push(...ans.data.answerContent)
            return {
              id: ans.data.memberId,
              name: ans.data.memberName || ans.data.memberId,
              answer: ans.data.answer,
              answerContent: ans.data.answerContent,
              allDataOpContent: [...temp, ...data.questionContent],
            }
          })
          const cursorAfter = data.historicalAnswers.after
          dispatch({
            type: SET_QUESTION_ANSWER_LIST,
            payload: {
              answers,
              ref: (cursorAfter && cursorAfter.ref) || null,
              ts: (cursorAfter && cursorAfter.ts) || null,
            },
          })
          resolve(data)
        } else {
          dispatch({
            type: SET_ERRORS,
            payload: 'Error',
          })
          reject('Error')
        }
      })
  })
}

export const getAnswer =
  (id: string, level: string, memberId: string, refId: string, refTs: string) => (dispatch: any) => {
    return new Promise<void>((resolve, reject) => {
      const queryString = (refId && refTs && `&afterCursorRef=${refId}&afterCursorTs=${refTs}`) || ''
      fetch(
        `${MAAYOT_API}question/answers?storyId=${id}&level=${
          level?.toLowerCase() || ''
        }&memberId=${memberId}${queryString}`,
        {
          method: 'GET',
          headers: JSON_HEADER,
        },
      )
        .then((resData) => resData.json())
        .then((data) => {
          if (data && Object.keys(data) && Object.keys(data).length > 0) {
            const answers = data.data.map((data) => {
              return {
                id: data.data.memberId,
                name: data.data.memberName || data.data.memberId,
                answer: data.data.answer,
                answerContent: data.data.answerContent,
              }
            })
            const cursorAfter = data.after
            dispatch({
              type: SET_QUESTION_ANSWER_LIST,
              payload: {
                answers,
                ref: (cursorAfter && cursorAfter.ref) || null,
                ts: (cursorAfter && cursorAfter.ts) || null,
              },
            })
            resolve()
          } else {
            dispatch({
              type: SET_ERRORS,
              payload: 'Error',
            })
            reject()
          }
        })
    })
  }

export const submitAnswer =
  (id: string, memberId: string, memberName: string, value: string, allowCorrection: boolean, level: string) =>
  (dispatch: any) => {
    return new Promise((resolve, reject) => {
      const content = {
        memberId,
        memberName,
        storyId: id,
        answer: value,
        correctionAllowedByTeacher: allowCorrection,
        level,
      }
      return fetch(`${MAAYOT_API}question`, {
        method: 'POST',
        headers: JSON_HEADER,
        body: JSON.stringify(content),
      })
        .then((resData) => resData.json())
        .then((data) => {
          if (data && !data.status && Object.keys(data) && Object.keys(data).length > 0) {
            dispatch({
              type: SET_SUBMIT_QUESTION,
              payload: data.data.answer,
            })
            resolve(data)
          } else {
            dispatch({
              type: SET_ERRORS,
              payload: data.message.message,
            })
            reject(data.message.message)
          }
        })
    })
  }
